import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BlogPostLink } from 'components/blog-post-link';
import { Button } from 'components/button-new';
import {
    CategoryBanner,
    CategoryBannerProps,
} from 'components/category-banner';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { useFilteredBlogPosts } from 'modules/footer-section/components/latest-related-articles-section/hooks';
import { arrayOf, bool, shape, string } from 'prop-types';

const SSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.75rem;
`;

const SBlogPostsContainer = styled.div`
    display: grid;
    justify-content: center;
    gap: 3rem 1rem;
    grid-template-columns: repeat(3, 1fr);
    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${CONSTANTS.MEDIA.max500`
        grid-template-columns: 1fr;
    `}
`;

const SButton = styled(Button)`
    margin-bottom: 1.5rem;
`;

const Props = {
    genre: string.isRequired,
    genreKey: string.isRequired,
    tags: arrayOf(string),
    currentPostId: string,
    hideTitle: bool,
    categoryBanner: CategoryBannerProps,
    showAllArticles: bool,
    button: shape({
        to: string,
        label: string,
    }),
};

export const LatestRelatedArticlesSection = ({
    tags,
    genre,
    genreKey,
    currentPostId,
    categoryBanner,
    hideTitle,
    showAllArticles,
    button,
}) => {
    const filteredData = useFilteredBlogPosts({
        tags,
        genre,
        genreKey,
    });

    const filteredDataWithoutCurrentPost = filteredData.filter(
        (data) => data.node.id !== currentPostId,
    );
    const recentArticles = showAllArticles
        ? filteredDataWithoutCurrentPost
        : [...filteredDataWithoutCurrentPost.slice(0, 3)];

    if (recentArticles.length === 0) {
        return null;
    }

    return (
        <Container>
            <SSubContainer>
                {!hideTitle && (
                    <HeaderSecond>
                        <FormattedMessage id="general.relatedArticles" />
                    </HeaderSecond>
                )}
                {categoryBanner && <CategoryBanner {...categoryBanner} />}
                <SBlogPostsContainer>
                    {recentArticles.map((item) => (
                        <BlogPostLink
                            key={item?.node?.id}
                            blogPost={item?.node}
                            withDescription
                        />
                    ))}
                </SBlogPostsContainer>
                {button && (
                    <SButton to={button.to} component="gatsby-link">
                        <FormattedMessage id={button.label} />
                    </SButton>
                )}
            </SSubContainer>
        </Container>
    );
};

LatestRelatedArticlesSection.propTypes = Props;
export { Props as LatestRelatedArticlesSectionProps };
