import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Stats } from 'components/stats';
import { string } from 'prop-types';

export const StatsSection = ({ className }) => {
    return (
        <Container className={className} id="stats">
            <Stats />
        </Container>
    );
};

StatsSection.propTypes = {
    className: string,
};
